import React, { useEffect, useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ContactAutocomplete from './ContactAutocomplete';
import api from '../../Services/Api';

function Mail({ mail, setMail, loaded }) {
    const [newMail, setNewMail] = useState({
        useremail: mail.useremail || '',
        username: mail.username || '',
        recipient: mail.recipient || [],
        subject: mail.subject || '',
        message: mail.message || '',
        options: mail.options || []
    });
    if (newMail.options == null) {
        newMail.options = newMail.recipient;
    }
    if (newMail.recipient.length === 0) {
        newMail.recipient.push({});
    }

    if (newMail.options.length === 0 && newMail.recipient.length > 0) {
        newMail.options = [...newMail.recipient];
    }
    loaded();
    return (
        <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                <Typography variant="h6">Destinatarios</Typography>
                <AddCircleIcon onClick={() => {
                    if(mail.recipient == null) mail.recipient = [];
                    mail.recipient.push({});
                    var newData = { useremail: newMail.useremail, username: newMail.username, recipient: mail.recipient, subject: newMail.subject, message: newMail.message }
                    setNewMail(newData);
                    setMail(newData);
                }
                } />
            </Stack>
            {mail.recipient != null && mail.recipient.map((recipient, index) => {
                return (
                    <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                        <ContactAutocomplete opts={mail.options} contact={recipient}
                            setContact={(contact) => {
                                mail.recipient[index] = contact;
                                var newData = { useremail: newMail.useremail, username: newMail.username, recipient: mail.recipient, subject: newMail.subject, message: newMail.message }
                                setNewMail(newData);
                                setMail(newData);
                            }} />
                        <RemoveCircleIcon onClick={() => {
                            mail.recipient.splice(index, 1);
                            var newData = { useremail: newMail.useremail, username: newMail.username, recipient: mail.recipient, subject: newMail.subject, message: newMail.message }
                            setNewMail(newData);
                            setMail(newData);
                        }} />
                    </Stack>
                )
            })
            }

            <TextField
                fullWidth
                id="subject"
                label="Asunto"
                variant="standard"
                defaultValue={mail.subject}
                onChange={(event) => {
                    var newData = { useremail: newMail.useremail, username: newMail.username, recipient: newMail.recipient, subject: event.target.value, message: newMail.message }
                    setNewMail(newData);
                    setMail(newData);
                }}
            />

            <TextField
                fullWidth
                id="content"
                label="Mensaje"
                variant="standard"
                multiline
                defaultValue={mail.message}
                onChange={(event) => {
                    var newData = { useremail: newMail.useremail, username: newMail.username, recipient: newMail.recipient, subject: newMail.subject, message: event.target.value }
                    setNewMail(newData);
                    setMail(newData);
                }}
            />

        </Stack>
    );
}

export default Mail;