import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import api from "../../Services/Api";
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";

const PipelineStages = ({stagePipeline, selectStage, stages, loaded, setNextDisabled}) => {
    const [stage, setStage] = useState({id: "", label: ""});

    useEffect(() => {
        if(stagePipeline != undefined) {
            setNextDisabled(false)
            let stageToSelect = stages.filter((stage) => stage.id == stagePipeline.id)
            if(stageToSelect.length > 0) {
                setStage(stageToSelect[0]);
                setNextDisabled(false)
            }
        }
        loaded();
        }, []);

    return (
        <Stack spacing={2} alignItems="center" style={{ padding: "2rem" }}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={stage.id}
                onChange={(event) => {
                    setNextDisabled(false)
                    let stageToSelect = stages.filter((stage) => stage.id == event.target.value)[0]
                    setStage(stageToSelect);
                    selectStage({
                        id: stageToSelect.id,
                        name: stageToSelect.label
                    }, false);
                }}
            >
                {
                    stages.map((stage) => {
                        return (<FormControlLabel value={stage.id} control={<Radio />} label={stage.label} />)
                    })
                }
            </RadioGroup>
        </Stack>
    );
}

export default PipelineStages;