import styles from '../App.module.css';
import { useState, useEffect, useRef } from 'react'
import { Box, Dialog, DialogActions, DialogContent, Button, CircularProgress, Grid, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import api from '../Services/Api';
import Company from './components/Company';
import Deal from './components/Deal';
import Task from './components/Task';
import Contact from './components/Contact';
import Meeting from './components/Meeting';
import Pipeline from './components/Pipeline';
import PipelineStages from './components/PipelineStages';
import * as Sentry from "@sentry/browser";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Lottie from 'react-lottie';
import animationProcessing from '../images/animation_processing.json';
import Mail from './components/Mail';
import Note from './components/Notes';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import validate from 'node-cron/src/pattern-validation';

function Validate() {
    const processingAnimation = {
        loop: true,
        autoplay: true,
        animationData: animationProcessing,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const { state } = useLocation();
    const { text } = state;

    const navigate = useNavigate();
    var clear = false

    function setClear(val) {
        clear = val
    }

    //Errors handling
    const [error, setError] = useState(false)
    const [errorTitle, setErrorTitle] = useState("Error desconocido")
    const [errorType, setErrorType] = useState(null)
    const [userAlreadyExists, setUserAlreadyExists] = useState(false)
    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [processingData, setProcessingData] = useState(true)
    const [titleProcessing, setTitleProcessing] = useState("Identificando acciones...")
    const [open, setOpen] = useState(false);
    //Success handling
    const [success, setSuccess] = useState(false)
    const [textProcessed, setTextProcessed] = useState(false)
    const [validateOptions, setValidateOptions] = useState([])
    const [nextDisabled, setNextDisabled] = useState(false) 
    var json = useRef({})
    var index = useRef(0)
    var socket = api.socket;

    useEffect(() => {
        
        
        if (!textProcessed) {
            setTextProcessed(true)
            sendText(text);
        }
        socket.on('closeText', disconnect);
        socket.on('progress', progressUpdate);
        socket.on('finished', textCallback);
        socket.on('actions', actionsCallback);
        socket.on('actionsUpdate', actionsUpdateCallback);
        socket.on('error', errorCallback);
        return () => {
            socket.off('connect', () => {});
            socket.off('closeText', disconnect);
            socket.off('progress', progressUpdate);
            socket.off('finished', textCallback);
            socket.off('actions', actionsCallback);
            socket.off('actionsUpdate', actionsUpdateCallback);
            socket.off('error', errorCallback);
        };
    },[validateOptions]);

    function disconnect() {
        console.log('disconnected');
        socket.off('connect', () => {});
            socket.off('closeText', disconnect);
            socket.off('progress', progressUpdate);
            socket.off('finished', textCallback);
            socket.off('actions', actionsCallback);
            socket.off('actionsUpdate', actionsUpdateCallback);
            socket.off('error', errorCallback);
        socket.disconnect();
        socket.close();
    }
    function progressUpdate(progress) {
        console.log(progress);
        setTitleProcessing(progress);
        // updateProgress(progress);
    }

    function textCallback(response) {
        console.log('textCallback');
    }

    function errorCallback(error) {
        Sentry.captureException(error);
        setError(true)
        setLoading(true)
        setProcessing(false)
        if(error.text) {
            navigate("/app", { state: { text: error.text } })
        }
        /*if (error.code == "ERR_BAD_REQUEST" && error.status != 401) {
            api.processError(error.response.data, reject);
        } else {
        }*/
    }

    function actionsCallback(actions) {
        /*if (actions == undefined || Object.keys(actions).length <= 0) {
            Sentry.captureException(new Error("No actions found"));
            setError(true)
            setLoading(true)
            setProcessing(false)
            return
        } else {
            
        }*/
        json.current = actions
        setProcessingData(true)
        processSuccess(json.current);
    }

    function actionsUpdateCallback(action) {
        console.log("Action update", action)
        var objIndex = validateOptions.findIndex(option => option.type == action.type);
        if (objIndex == -1) {
            console.log("Action not found", action.type)
            return
        }
        
        switch (action.type) {
            case api.ERROR.DEAL:
                json.current.deal = action.data
                break;
            case api.ERROR.COMPANY:
                json.current.company = action.data
                break;
            case api.ERROR.CONTACT:
                json.current.contact = action.data
                break;
            case api.ERROR.TASK:
                json.current.task = action.data
                break;
            case api.ERROR.MEETING:
                json.current.meeting = action.data
                processMeeting();
                break;
            case api.ERROR.MAIL:
                json.current.mail = action.data
                processMail();
                break;
            case api.ERROR.NOTE:
                json.current.note = action.data
                processNote();
                break;
            default:
                console.log("Unknown action type:", action.type);
                break;
        }

        if (objIndex == index.current) {
            setLoading(false)
            setProcessingData(false)
        }
        validateOptions[objIndex].dataProcessed = true
        setValidateOptions([...validateOptions])
    }

    const handleClose = () => {
        setOpen(false);
    };

    const sendJson = () => {
        clearBeforeSend()
        setProcessing(true)
        disconnect()
        api.CreateDealFromJson(json.current).then((result) => {
            setProcessing(false)
            setLoading(false)
            setSuccess(true)
            setProcessing(false)
            socket.off('closeText', disconnect);
            socket.off('progress', progressUpdate);
            socket.off('finished', textCallback);
            socket.off('actions', actionsCallback);
            socket.off('actionsUpdate', actionsUpdateCallback);
            socket.off('error', errorCallback);
            navigate("/app", { state: { ok: true, fail: false } })
        }).catch((error) => {
            setProcessing(false)
            index.current = 0
            Sentry.captureException(error);
            setErrorType(error.error)
            var options = [{ type: error.error, dataProcessed: true }]
            setValidateOptions(options)
            json.current = error.originalJson
            switch (error.error) {
                case api.ERROR.COMPANY:
                    json.current.company = {}
                    json.current.company.options = error.options
                    break;
                case api.ERROR.CONTACT:
                    json.current.contact = {}
                    json.current.contact.options = error.options
                    break;
                case api.ERROR.DEAL:
                    options.push({ type: api.ERROR.PIPELINE, dataProcessed: true })
                    options.push({ type: api.ERROR.PIPELINE_STAGES, dataProcessed: true })
                default:
                    break;
            }
            setValidateOptions(options)
            processError(options)
        })
    }

    const clearBeforeSend = () => {
        setErrorTitle("")
        index.current = 0
        setValidateOptions([])
    }

    const sendText = (text) => {
        setProcessingData(true)
        socket = api.socket;
        if (socket.connected) {
            socket.emit('text', { data: { text: text } });
        } else {
            socket.connect();
            socket.on('connect', () => {
                console.log('connected');
            });
            socket.emit('text', { data: { text: text } });
        }
    }

    const processSuccess = (json) => {
        setLoading(true)
        setProcessing(false)
        var options = []
        if (json.deal) {
            options.push({ type: api.ERROR.DEAL, dataProcessed: false })
            options.push({ type: api.ERROR.PIPELINE, dataProcessed: true })
            options.push({ type: api.ERROR.PIPELINE_STAGES, dataProcessed: true })
        }

        if (json.contact) {
            options.push({ type: api.ERROR.CONTACT, dataProcessed: false })
        }
        if (json.company) {
            options.push({ type: api.ERROR.COMPANY, dataProcessed: false })
        }
        if (json.task) {
            options.push({ type: api.ERROR.TASK, dataProcessed: false })
        }
        if (json.meeting) {
            options.push({ type: api.ERROR.MEETING, dataProcessed: false })
        }
        if (json.note) {
            options.push({ type: api.ERROR.NOTE, dataProcessed: false })
        }
        if (json.mail) {
            options.push({ type: api.ERROR.MAIL, dataProcessed: false })
        }
        setValidateOptions(options)
        processError(options)
    }

    const processError = (options) => {
        let error = options[index.current]
        console.log("Processing error", index.current, error, json.current)
        setProcessing(false)
        if (error && error.type) {
            setErrorType(error.type)
            switch (error.type) {
                case api.ERROR.PIPELINE:
                    setErrorTitle("Seleccione un pipeline")
                    break;
                case api.ERROR.PIPELINE_STAGES:
                    setErrorTitle("Seleccione una etapa")
                    break;
                case api.ERROR.COMPANY:
                    setErrorTitle("Valide los datos de la compañía")
                    break;
                case api.ERROR.CONTACT:
                    setErrorTitle("Valide los datos del contacto")
                    break;
                case api.ERROR.TASK:
                    setErrorTitle("Valide los datos de la tarea")
                    break;
                case api.ERROR.MEETING:
                    setErrorTitle("Valide los datos de la reunión")
                    processMeeting();
                    break;
                case api.ERROR.DEAL:
                    setErrorTitle("Valide los datos del negocio")
                    break;
                case api.ERROR.MAIL:
                    setErrorTitle("Valide los datos del correo")
                    processMail();
                    break;
                case api.ERROR.NOTE:
                    setErrorTitle("Valide los datos de la nota")
                    processNote();
                    break;
                default:
                    Sentry.captureException(error);
                    if (error.status == 401) {
                        //Cookies.set('token', "", { expires: 7, secure: true });
                        navigate("/")
                    } else {
                        navigate("/app", { state: { ok: false, fail: true } })
                    }
                    console.log("Error desconocido")
            }
        } else {
            console.log("Error type is undefined or null")
            errorCallback(error)
        }
    }


    const createContact = () => {
        if (json.current.contact.create) {
            api.createContact(json.current.contact).then((result) => {
                console.log("Setting contact id", result)
                json.current.contact = { 
                    email: result.email,
                    firstname: result.firstname,
                    lastname: result.lastname,
                    id: result.id, 
                    create: false, 
                    options: [result] 
                }
                index.current = index.current + 1
                setLoading(true)
                processError(validateOptions)
            }).catch((error) => {
                setLoading(false)
                setUserAlreadyExists(true)
                Sentry.captureException(error);
            })
        } else {
            index.current = index.current + 1
            processError(validateOptions)
        }
    }

    const processMeeting = () => {
        if (Object.keys(json.current.meeting).length > 0 && json.current.contact) {
            var contact = json.current.contact
            delete contact.options
            if (json.current.meeting.attendees == undefined || json.current.meeting.attendees.length <= 0) {
                json.current.meeting.attendees = []
                json.current.meeting.attendees.push(contact)
            } else {
                json.current.meeting.attendees[0] = contact
            }
            if (json.current.meeting.options == undefined || json.current.meeting.options.length <= 0) {
                json.current.meeting.options = []
            }
            json.current.meeting.options.push(contact)
        }
    }

    const processMail = () => {
        console.log("Processing mail", json.current.mail)
        if (Object.keys(json.current.mail).length > 0 && json.current.contact) {
            var contact = json.current.contact
            delete contact.options
            if (json.current.mail.recipient == undefined || json.current.mail.recipient.length <= 0) {
                json.current.mail.recipient = []
                json.current.mail.recipient.push(contact)
            } else {
                json.current.mail.recipient[0] = contact
            }
            if (json.current.mail.options == undefined || json.current.mail.options.length <= 0) {
                json.current.mail.options = []
            }
            json.current.mail.options.push(contact)
        }
    }
    
    const processNote = () => {
        if (Object.keys(json.current.note).length > 0 && json.current.note.contacts && json.current.contact) {
            var contact = json.current.contact
            delete contact.options
            if (json.current.note.contacts == undefined || json.current.note.contacts.length <= 0) {
                json.current.note.contacts = []
                json.current.note.contacts.push(contact)
            } else {
                json.current.note.contacts[0] = contact
            }
            if (json.current.note.contactsOptions == undefined || json.current.note.contactsOptions.length <= 0) {
                json.current.note.contactsOptions = []
            }
            json.current.note.contactsOptions.push(contact)
        }
        if (Object.keys(json.current.note).length > 0 && json.current.note.companies && json.current.company) {
            var company = json.current.company
            delete company.options
            if (json.current.note.companies == undefined || json.current.note.companies.length <= 0) {
            json.current.note.companies = []
            json.current.note.companies.push(company)
            } else {
            json.current.note.companies[0] = company
            }
            if (json.current.note.companiesOptions == undefined || json.current.note.companiesOptions.length <= 0) {
            json.current.note.companiesOptions = []
            }
            json.current.note.companiesOptions.push(company)
        }
        
    }

    return (
        <Stack spacing={2} className={styles.BackgroundGlobalNoStyle}>
            {index.current > 0 &&
                <div style={{ width: "100%", }}>
                    <IconButton onClick={() => { index.current = index.current - 1; processError(validateOptions); }} style={{ color: "white" }}>
                        <ArrowBackIcon style={{ fontSize: "35px" }} />
                    </IconButton>
                </div>

            }
            <Fade in={!processing && !processingData}>
                <Grid container style={{ flexDirection: 'column', alignItems: 'center', color: "white", marginTop: 0 }}>
                    <Stack spacing={2} alignItems="center" style={{ padding: "1rem", width: "100%" }}>

                        <Typography variant="h5" gutterBottom align="center">{errorTitle}</Typography>

                        {errorType == api.ERROR.PIPELINE && Object.keys(json.current.deal).length > 0 &&
                            <Pipeline
                                dealPipeline={json.current.deal.pipeline}
                                setNextDisabled={setNextDisabled}
                                loaded={() => { setLoading(false) }}
                                selectPipeline={(pipeline, justOne) => {
                                    json.current.deal.pipeline = pipeline
                                    if (justOne) {
                                        setLoading(true)
                                        index.current = index.current + 1
                                        processError(validateOptions)
                                    }
                                }
                                } />
                        }

                        {errorType == api.ERROR.PIPELINE_STAGES  && Object.keys(json.current.deal).length > 0&&
                            <PipelineStages
                                stagePipeline={json.current.deal.stage}
                                stages={json.current.deal.pipeline.stages}
                                loaded={() => setLoading(false)}
                                setNextDisabled={setNextDisabled}
                                selectStage={(stage, justOne) => {
                                    json.current.deal.stage = stage
                                    if (justOne) {
                                        setLoading(true)
                                        index.current = index.current + 1
                                        processError(validateOptions)
                                    }
                                }
                                } />
                        }
                        {errorType == api.ERROR.COMPANY && Object.keys(json.current.company).length > 0 &&
                            <Company
                            loaded={() => { setLoading(false); setNextDisabled(false)} }
                            company={json.current.company} selectCompany={company => json.current.company = company} setCompany={company => json.current.company = company} />
                        }

                        {errorType == api.ERROR.DEAL && Object.keys(json.current.deal).length > 0 &&
                            <Deal
                            loaded={() => { setLoading(false); setNextDisabled(false)} }
                            deal={json.current.deal} createDeal={deal => json.current.deal = deal} />
                        }

                        {errorType == api.ERROR.TASK && Object.keys(json.current.task).length > 0 &&
                            <Task
                                loaded={() => { setLoading(false); setNextDisabled(false)} }
                                task={json.current.task} setTask={task => json.current.task = task} />
                        }

                        {errorType == api.ERROR.MEETING && Object.keys(json.current.meeting).length > 1 &&
                            <Meeting
                            loaded={() => { setLoading(false); setNextDisabled(false)} }
                            meeting={json.current.meeting} createMeeting={meeting => json.current.meeting = meeting} />
                        }

                        {errorType == api.ERROR.CONTACT && Object.keys(json.current.contact).length > 0 &&
                            <Contact
                            loaded={() => { setLoading(false); setNextDisabled(false)} }
                            contact={json.current.contact} setContact={contact => json.current.contact = contact} userAlreadyExists={userAlreadyExists} />
                        }

                        {errorType == api.ERROR.MAIL && json.current.mail && json.current.mail.message &&
                            <Mail
                            loaded={() => { setLoading(false); setNextDisabled(false)} }
                            mail={json.current.mail} setMail={mail => json.current.mail = mail} />
                        }

                        {errorType == api.ERROR.NOTE && Object.keys(json.current.note).length > 1 &&
                            <Note
                            loaded={() => { setLoading(false); setNextDisabled(false)} }
                            note={json.current.note} setNote={note => json.current.note = note} />
                        }

                        <Stack direction="column" spacing={2} style={{ width: "100%" }}>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button disabled={loading || nextDisabled} fullWidth variant="contained" color="primary" onClick={() => {
                                    setLoading(true)
                                    setNextDisabled(true)
                                    if (index.current + 1 < validateOptions.length) {
                                        if (errorType == api.ERROR.CONTACT) {
                                            createContact()
                                        } else {
                                            index.current = index.current + 1
                                            processError(validateOptions)
                                        }
                                    } else {
                                        sendJson();
                                        setProcessing(true);
                                    }
                                }}>
                                    {index.current < validateOptions.length - 1 && "Siguiente"}
                                    {index.current == validateOptions.length - 1 && "Finalizar"}
                                    {loading && <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "white",
                                            position: 'absolute',
                                            top: '50%',
                                            right: '5%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                    }
                                </Button>
                            </Box>
                            <Button variant="outlined" color="error" onClick={() => { setOpen(true) }}>Cancelar</Button>

                        </Stack>

                        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                            <DialogContent>
                                ¿Seguro que deseas cancelar?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>cerrar</Button>
                                <Button onClick={() => {
                                    disconnect();
                                    navigate("/app");
                                }}>OK</Button>
                            </DialogActions>
                        </Dialog>
                    </Stack>
                </Grid>
            </Fade>
            <Fade in={processingData}>
                <Stack spacing={2} className={styles.marginTop}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                        <Typography variant="h5" gutterBottom align="center" style={{ color: "white" }}>{titleProcessing}</Typography>
                        <Lottie
                            options={processingAnimation}
                            height={"125px"}
                            width={"125px"}
                            style={{ marginTop: "102px" }}
                        />

                    </div>
                </Stack>
            </Fade>

            <Fade in={processing}>
                <Stack spacing={2} className={styles.marginTop} style={{ position: "absolute", left: 0, right: 0 }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                        <Typography variant="h5" gutterBottom align="center" style={{ color: "white" }}>Enviando datos</Typography>
                        <Lottie
                            options={processingAnimation}
                            height={"125px"}
                            width={"125px"}
                            style={{ marginTop: "102px" }}
                        />

                    </div>
                </Stack>
            </Fade>
            <Dialog
        open={error}
        disableEscapeKeyDown={true}
        onClose={() => {
            setError(false)
            disconnect();
            navigate("/app")
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Error procesando solicitud
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Inténtelo de nuevo.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>{
            disconnect();
            navigate("/app");
          }}>Vale</Button>
        </DialogActions>
      </Dialog>
        </Stack>
    );
}

export default Validate;
