import { Stack, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Properties from './Properties';
import React from 'react';

const Deal = ({ deal, createDeal, loaded }) => {
    const [newDeal, setNewDeal] = React.useState({
        dealname: deal.dealname || '',
        amount: deal.amount || '',
        estimatedDateClosed: deal.estimatedDateClosed || '',
        pipeline: deal.pipeline || '',
        dealstage: deal.dealstage || '',
        dealtype: deal.dealtype || '',
        description: deal.description || '',
        properties: deal.properties || []
    });

    const newData = (field, value) => {
        setNewDeal({ ...newDeal, [field]: value });
        createDeal({ ...newDeal, [field]: value });
    };


    React.useEffect(() => {
        loaded();
    }, [deal]);

    return (
        <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            <TextField
                fullWidth
                id="dealName"
                label="Nombre del negocio"
                variant="standard"
                defaultValue={newDeal.dealname}
                onChange={(event) => newData("dealname", event.target.value)}
            />

            <TextField
                fullWidth
                id="dealValue"
                label="Valor del negocio"
                variant="standard"
                defaultValue={newDeal.amount}
                onChange={(event) => newData("amount", event.target.value)}
            />

            <TextField
                fullWidth
                id="dealClose"
                label="Fecha de cierre del negocio"
                type="date"
                variant="standard"
                InputLabelProps={{
                    shrink: true,
                }}
                defaultValue={newDeal.estimatedDateClosed}
                onChange={(event) => newData("estimatedDateClosed", event.target.value)}
            />
            {
                newDeal.properties != undefined && <Properties properties={newDeal.properties} setProperties={
                    (properties) => {
                        setNewDeal({ ...newDeal, properties: properties });
                        createDeal({ ...newDeal, properties: properties });
                    }
                } />
            }
        </Stack>
    );
}

export default Deal;
