import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import Api from '../../Services/Api';
import * as Sentry from "@sentry/browser";
import React, { useState, useEffect, useRef } from 'react';

const CompanyAutocomplete = ({ opts, company, setCompany }) => {
    const [options, setOptions] = useState([]);
    const [after, setAfter] = useState(undefined);
    const [inputValue, setInputValue] = useState(opts.find((option) => option.id === company.id));
    const previousController = useRef();

    useEffect(() => {
        if (opts && opts.length > 0) {
            setOptions(opts);
        }
    }, [opts]);

    const onInputChangeCompany = (event, value, reason) => {
        if (value && reason === "input") {
            if (previousController.current) {
                previousController.current.abort();
              }
              const controller = new AbortController();
              const signal = controller.signal;
              previousController.current = controller;
            
            Api.fetchCompanies(value, after, signal)
                .then((result) => {
                    if (result.options.length > 0) {
                        setOptions(result.options);
                    }
                    setAfter(result.after);
                })
                .catch((error) => {
                    setAfter(undefined);
                    Sentry.captureException(error);
                    console.log(error);
                });
        }
    };

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            style={{ width: "100%" }}
            defaultValue={inputValue}
            onInputChange={onInputChangeCompany}
            onChange={(event, newValue) => {
                if (newValue && newValue.id) {
                    setCompany({ id: newValue.id, name: newValue.name, domain: newValue.domain });
                }
            }}
            options={options.sort((a, b) => {
                if (a.name && b.name) {
                    return b.name.localeCompare(a.name);
                }
                return 0;
            })}
            groupBy={(option) => option.name ? option.name.toUpperCase() : ''}
            autoSelect={true}
            getOptionLabel={(option) => (option.name || '') + (option.domain ? ' ' + option.domain : '')}
            renderInput={(params) => <TextField {...params} label="Selecciona una compañía" />}
            renderOption={(props, option) => (
                <Stack spacing={1} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Typography variant="subtitle1" style={{ width: "100%", textAlign: "left" }}>{option.name || ''}</Typography>
                    <Typography variant="caption" style={{ width: "100%", textAlign: "left" }} color="text.secondary">{option.domain || ''}</Typography>
                </Stack>
            )}
        />
    );
}

export default CompanyAutocomplete;