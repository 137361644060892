import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import api from "../../Services/Api";
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";

const Pipeline = ({dealPipeline, selectPipeline, loaded, setNextDisabled}) => {
    const [pipeline, setPipeline] = useState({id: "", label: ""});
    const [options, setOptions] = useState([]);
    useEffect(() => {
        api.fetchPipelines().then((result) => {
            if(result.length == 1) {
                setNextDisabled(false)
                setPipeline(result[0])
                selectPipeline(result[0], true)
            }
            if(dealPipeline != undefined) {
                setNextDisabled(false)
                var pipelineToSelect = result.filter((pipeline) => pipeline.id == dealPipeline.id)
                if(pipelineToSelect.length > 0) {
                    pipelineToSelect = pipelineToSelect[0]
                    setPipeline(pipelineToSelect)
                }
                selectPipeline({
                    id: pipelineToSelect.id,
                    name: pipelineToSelect.label,
                    stages: pipelineToSelect.stages
                }, false);
            }
            setOptions(result);
            loaded();
          }).catch((error) => {
            Sentry.captureException(error);
            console.log(error)
          })

        }, []);

    return (
        <Stack spacing={2} alignItems="center" style={{ padding: "2rem" }}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={pipeline.id}
                onChange={(event) => {
                    setNextDisabled(false)
                    let pipelineToSelect = options.filter((pipeline) => pipeline.id == event.target.value)[0]
                    setPipeline(pipelineToSelect);
                    selectPipeline({
                        id: pipelineToSelect.id,
                        name: pipelineToSelect.label,
                        stages: pipelineToSelect.stages
                    }, false);
                    // event.target.value;
                }}
            >
                {
                    options.map((pipeline) => {
                        return (<FormControlLabel value={pipeline.id} control={<Radio />} label={pipeline.label} />)
                    })
                }
            </RadioGroup>
        </Stack>
    );
}

export default Pipeline;