import axios from "axios";
import Cookies from 'js-cookie';
import { io } from 'socket.io-client';

const baseURL = process.env.REACT_APP_API_URL || (process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://app.labia.pro/api");
const socketURL = baseURL.replace('/api', '');
const socket = io(socketURL, {
    autoConnect: false,
    withCredentials: true,
    path: process.env.NODE_ENV === "development" ? "/socket" : "/api/socket"
  });
const ERROR = {
    PIPELINE: 'PIPELINE',
    PIPELINE_STAGES: 'PIPELINE_STAGES',
    COMPANY: 'COMPANY',
    CONTACT: 'CONTACT',
    TASK: 'TASK',
    MEETING: 'MEETING',
    DEAL: 'DEAL',
    MAIL: 'MAIL',
    NOTE: 'NOTE',
    UNKNOWN: 'UNKNOWN'
}

//axios.defaults.headers.common['sessionId'] = Cookies.get('token');
axios.defaults.withCredentials = true;

function CreateDealFromJson(data) {
    return new Promise((resolve, reject) => {
        console.log(JSON.stringify(data));
        /*f (data.deal != undefined && data.deal.pipeline != undefined) {
            delete data.deal.pipeline.stages;
        }
        if (data.note != undefined) {
            delete data.note.contactsOptions;
            delete data.note.companiesOptions;
            delete data.note.dealsOptions;
        }
        Object.keys(data).forEach(key => {
            if (data[key].options != undefined) {
                delete data[key];
            }
        });*/
        console.log(JSON.stringify(data));
        axios.post(`${baseURL}/createDeal`, data)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                console.log(response.response);
                if (response.code == "ERR_BAD_REQUEST" && response.status != 401) {
                    processError(response.response.data, reject);
                } else {
                    reject(response.response)
                }
            });
    });
}


function CreateJSONFromText(text, updateProgress, resolve, reject) {
    if (socket.connected) {
        socket.emit('text', { data: { text: text } });
    } else {
        socket.connect();
        socket.on('connect', () => {
            console.log('connected');
            socket.emit('text', { data: { text: text } });
        });
    }
    socket.on('disconnect', () => {
        console.log('disconnected');
    });
    socket.on('progress', (progress) => {
        console.log(progress);
        updateProgress(progress);
    });
    socket.on('text', (response) => {
        resolve(response);
        socket.disconnect();
        socket.close();
    });
    socket.on('actions', (actions) => {
        
    });

    socket.on('error', (error) => {
        socket.disconnect();
        socket.close();
        console.log(error);
        if (error.code == "ERR_BAD_REQUEST" && error.status != 401) {
            processError(error.response.data, reject);
        } else {
            reject(error.response)
        }
    });
}


function UploadAudio(audio, updateProgress) {
    return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
        bodyFormData.append('audio', audio, `${makeid(5)}.webm`);
        axios({
            method: "post",
            url: `${baseURL}/audio`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: updateProgress
          })
            .then(function (response) {
                console.log(response);
                resolve(response.data);
            })
            .catch(function (response) {
                console.log(response.response);
                if (response.code == "ERR_BAD_REQUEST" && response.response.status != 401) {
                    processError(response.response.data, reject);
                } else {
                    reject(response.response)
                }
            });
    });
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

function processError(error, reject) {
    if (error.message) {
        let errorType = error.message.toUpperCase()
        let originalJson = error.originalJson;
        switch (errorType) {
            case 'PIPELINE':
                reject({error: ERROR.PIPELINE, originalJson: originalJson, options: error.options})
                break;
            case 'COMPANY':
                reject({error: ERROR.COMPANY, originalJson: originalJson, options: error.options})
                break;
            case 'CONTACT':
                reject({error: ERROR.CONTACT, originalJson: originalJson, options: error.options})
                break;
            case 'TASK':
                reject({error: ERROR.TASK, originalJson: originalJson, options: error.options})
                break;
            case 'MEETING':
                reject({error: ERROR.MEETING, originalJson: originalJson, options: error.options})
                break;
            case 'DEAL':
                reject({error: ERROR.DEAL, originalJson: originalJson, options: error.options})
                break;
            case 'EMAIL':
                reject({error: ERROR.MAIL, originalJson: originalJson, options: error.options})
                break;
            case 'NOTE':
                reject({error: ERROR.NOTE, originalJson: originalJson, options: error.options})
                break;
            default:
                reject({error: ERROR.UNKNOWN, originalJson: originalJson, options: error.options})
        }
    }
}

function fetchCompanies(value, after, signal) {
    return new Promise((resolve, reject) => {
        var url = `${baseURL}/companies`;
        if (value) {
            url += `?companyName=${value}`;
        }
        if (after) {
            url += `&after=${after}`;
        }
        axios.get(url, { signal: signal })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchDeals(value, after, signal) {
    return new Promise((resolve, reject) => {
        var url = `${baseURL}/deals`;
        if (value) {
            url += `?deal=${value}`;
        }
        if (after) {
            url += `&after=${after}`;
        }
        axios.get(url, { signal: signal })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}


function fetchContacts(firstName, after, signal) {
    return new Promise((resolve, reject) => {
        var url = `${baseURL}/contacts`;
        if (firstName) {
            url += `?firstName=${firstName}`;
        }
        if (after) {
            url += `&after=${after}`;
        }
        axios.get(url, {signal: signal})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function getUserData() {
    return new Promise((resolve, reject) => {
        axios.get(`${baseURL}/user`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchPipelines() {
    return new Promise((resolve, reject) => {
        axios.get(`${baseURL}/pipelines`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function logout() {
    return new Promise((resolve, reject) => {
        axios.post(`${baseURL}/logout`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function createContact(data) {
    return new Promise((resolve, reject) => {
        axios.post(`${baseURL}/contacts`, data)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

export default {
    baseURL,
    ERROR,
    CreateDealFromJson,
    CreateJSONFromText,
    UploadAudio,
    fetchCompanies,
    fetchContacts,
    fetchPipelines,
    getUserData,
    createContact,
    fetchDeals,
    logout,
    processError,
    socket
}