import React from 'react';
import { Stack, TextField, RadioGroup, FormControlLabel, Radio, Typography, Select, MenuItem } from '@mui/material';

const Properties = ({ properties, setProperties }) => {
    const handlePropertyChange = (index, value) => {
        var updatedProperties = [...properties];
        updatedProperties[index].value = value;
        setProperties(updatedProperties);
    };

    return (
        <Stack spacing={2} style={{ width: "100%" }}>
            {properties.map((property, index) => (
                (property.options != undefined) ? (
                    <Stack spacing={2} style={{ width: "100%" }}>
                        <Typography variant="h6">{property.label}</Typography>
                        <Select
                            id={`property-${index}`}
                            value={property.value}
                            label={property.label}
                            onChange={(event) => handlePropertyChange(index, event.target.value)}
                        >
                            {property.options.map((option, optionIndex) => (
                                <MenuItem value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </Stack>
                ) : (
                    <TextField
                        key={index}
                        fullWidth
                        id={`property-${index}`}
                        label={property.label}
                        variant="standard"
                        type={property.type}
                        defaultValue={property.value}
                        helperText={property.description}
                        onChange={(event) => handlePropertyChange(index, event.target.value)}
                    />
                )
            ))}
        </Stack>
    );
};

export default Properties;
